import React from 'react'
import Page from '../components/Page'
import { StaticImage } from 'gatsby-plugin-image'

const ContactPage = () => (
  <Page title="Call us!" image={false}>
    <h1 className="text-2xl text-center w-full mt-8">CALL US!</h1>
    <div className="flex mt-8">
      <form className="bg-gray-800 text-gray-200" name="contact" method="POST" data-netlify="true">
        <input type="hidden" name="form-name" value="contact"/>
        <p className="p-4">
          <label>Your Name: <input type="text" name="name" /></label>   
        </p>
        <p className="p-4">
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        {/* <p className="p-4">
          <label>Your Role: <select name="role[]" multiple>
            <option value="leader">Leader</option>
            <option value="follower">Follower</option>
          </select></label>
        </p> */}
        <p className="p-4">
          <label>Message: <textarea name="message"></textarea></label>
        </p>
        <p className="p-4">
          <button className="bg-main p-4 text-gray-800 rounded" type="submit">Send</button>
        </p>
    </form>
     <StaticImage
       src="../images/contact.jpg"
       formats={['auto', 'webp', 'avif']}
       alt={"contact"}
       title={"contact"}
       className={"p-4"}
       placeholder="blurred"
      //  width={50}
      //  height={50}
     />
  </div>
  <p className="mt-8">
    IPPAL Translation Company<br/>
    Hergiswil, Switzerland - Palo Alto, CA, USA - Haikou, Hainan, China<br/>
    <br/>
    Voice/Chat: 001 650 402 6076<br/>
    Email: contact AT ip-pal DOT com<br/>
    Mail: Obermattweg 12, 6052 Hergiswil, NW, CH<br/>
  </p>
  </Page>
)

export default ContactPage
